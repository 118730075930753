import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { DialogAlertComponent } from "../shared/modals/dialog-alert/dialog-alert.component";
import { HttpParams, HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ApplicationProvider } from "../providers/providers";
import { EndpointProvider } from "../providers/endpoint/endpoint";
import { BodyConsultaCliente } from "../models/BodyConsultaCliente";
import { BodyValidarCliente } from "../models/BodyValidarCliente";
import { ReqOTP } from "../models/ReqOTP";
import datajson from "../../assets/config.json";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.css"],
})
export class InicioComponent implements OnInit {
  formInicio: FormGroup;
  formOTP: FormGroup;
  flagOtpValidation: boolean;
  jsonConfig: any = datajson;
  tipoDoc: any = [
    {
      id: "C",
      valor: "Cedula",
    },
    {
      id: "R",
      valor: "Ruc",
    },
    {
      id: "P",
      valor: "Pasaporte",
    },
  ];
  noDNI = [
    "1111111111",
    "2222222222",
    "3333333333",
    "4444444444",
    "5555555555",
    "6666666666",
    "7777777777",
    "8888888888",
    "9999999999",
    "0000000000",
  ];
  noRUC = [
    "1111111111111",
    "2222222222222",
    "3333333333333",
    "4444444444444",
    "5555555555555",
    "6666666666666",
    "7777777777777",
    "8888888888888",
    "9999999999999",
    "0000000000000",
  ];
  // Variable que permite cambiar la longitud de caracteres a ingresar en input identificacion
  length = "10";
  // Permite validar si la identificacion contiene solo digitos (CED, RUC) o digitos y letras (PAS)
  isNumber = true;
  referenciaOTP: string;
  body: BodyConsultaCliente;
  bodyValidar: BodyValidarCliente;
  validateOTP: ReqOTP;
  data: number = 1;
  dataServicioTemp: any;
  correoMask: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private coreService: ApplicationProvider,
    private endPoint: EndpointProvider
  ) {
    this.formInicio = formBuilder.group({
      tipoIdentificacion: [
        { value: "", disabled: false },
        [Validators.required],
      ],
      identificacion: [
        { value: "", disabled: false },
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
    });
    this.formOTP = formBuilder.group({
      codigo: [{ value: "", disabled: false }, [Validators.required]],
    });
  }

  async ngOnInit() {
    sessionStorage.clear();
    this.flagOtpValidation = false;
    const params = this.obtenerParametros(window.location);
    sessionStorage.origen = params["origen"];
    //const urlParams = new URLSearchParams(params);
    //sessionStorage.origen = urlParams.get('origen');
    this.jsonConfig = await this.http.get("assets/config.json").toPromise();
    if (
      sessionStorage.origen != "defensor" &&
      sessionStorage.origen != "cliente"
    ) {
      this.alertaRedirect();
    }

    this.formInicio.controls.tipoIdentificacion.setValue("C");
  }

  obtenerParametros(url) {
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.hash.substring(9);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  alertaRedirect() {
    const dialogRef = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content: "La url ingresada no es válida.",
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      window.location.href = this.jsonConfig["urlSitioBM"];
    });
  }

  seleccionTipoDoc() {
    // Cambia la validación del input identificación de acuerdo al tipo de documento
    switch (this.formInicio.get("tipoIdentificacion").value) {
      case "C":
        this.length = "10";
        this.isNumber = true;
        this.formInicio.get("identificacion").reset();
        this.formInicio
          .get("identificacion")
          .setValidators([
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(/^[0-9]*$/),
          ]);
        break;
      case "R":
        this.length = "13";
        this.isNumber = true;
        this.formInicio.get("identificacion").reset();
        this.formInicio
          .get("identificacion")
          .setValidators([
            Validators.required,
            Validators.minLength(13),
            Validators.pattern(/^[0-9]*$/),
          ]);
        break;
      case "P":
        //this.length = '8';
        this.length = "";
        this.isNumber = false;
        this.formInicio.get("identificacion").reset();
        this.formInicio
          .get("identificacion")
          .setValidators([Validators.required]); //, Validators.minLength(8),
        //Validators.pattern(/^[A-Za-z0-9]*$/)]);
        break;
      default:
        break;
    }
  }

  async consultar() {
    sessionStorage.tipoPersona =
      this.formInicio.get("tipoIdentificacion").value;
    //await this.bodyConsultaCliente();
    await this.bodyValidarCliente();
    this.spinner.show();
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.bodyValidar.TokenId = en.access_token;
    this.coreService.httpValidarCliente(this.bodyValidar).subscribe(
      async (data: any) => {
        this.spinner.hide();
        console.log("data validar", data);
        this.correoMask = data["CorreoMask"];
        this.sinotp();
      },
      (e) => {
        if (e.error) {
          this.spinner.hide();
          this.alerta(e.error);
        }
      }
    );
  }

  async sinotp() {
    await this.bodyConsultaCliente();
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.body.TokenId = en.access_token;
    this.spinner.show();
    this.coreService.httpConsultaCliente(this.body).subscribe(
      async (data: any) => {
        this.spinner.hide();

        this.dataServicioTemp = data;
        if (
          this.dataServicioTemp["identificacionCliente"] ===
          this.formInicio.controls.identificacion.value
        ) {
          sessionStorage.dataCliente = JSON.stringify(this.dataServicioTemp);
          sessionStorage.setItem("loggedIn", "1");
          this.flagOtpValidation = false;
          this.router.navigateByUrl("/consultas");
        } else {
          this.alertaOtp("Sus datos no coinciden.");
        }
      },
      (e) => {
        if (e.error) {
          this.spinner.hide();
          this.alerta(e.error);
        }
      }
    );
  }

  async bodyValidarCliente() {
    const data: any = {
      TokenId: "",
      Identificacion: "",
      Tipo: "",
    };
    this.bodyValidar = data;
    this.bodyValidar.Identificacion =
      this.formInicio.get("identificacion").value;
    this.bodyValidar.Tipo = this.formInicio.get("tipoIdentificacion").value;
  }

  async bodyConsultaCliente() {
    const data: any = {
      Identificacion: "",
      Tipo: "",
      TokenId: "",
    };
    this.body = data;
    this.body.Identificacion = this.formInicio.get("identificacion").value;
    this.body.Tipo = this.formInicio.get("tipoIdentificacion").value;
  }

  async validarOTP() {
    // Incluir servicio OTP
    this.spinner.show();
    const data: any = {
      Identificacion: "",
      Tipo: "",
      TokenId: "",
      CodigoOtp: "",
    };

    data.Identificacion = this.formInicio.controls.identificacion.value;
    data.Tipo = this.formInicio.controls.tipoIdentificacion.value;
    data.CodigoOtp = this.formOTP.controls.codigo.value;
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;
    this.coreService.httpValidarOTP(data).subscribe(
      async (dataOTP: any) => {
        await this.bodyConsultaCliente();
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        this.body.TokenId = en.access_token;
        this.spinner.show();
        this.coreService.httpConsultaCliente(this.body).subscribe(
          async (data: any) => {
            this.spinner.hide();

            this.dataServicioTemp = data;
            if (
              this.dataServicioTemp["identificacionCliente"] ===
              this.formInicio.controls.identificacion.value
            ) {
              sessionStorage.dataCliente = JSON.stringify(
                this.dataServicioTemp
              );
              sessionStorage.setItem("loggedIn", "1");
              this.flagOtpValidation = false;
              this.router.navigateByUrl("/consultas");
            } else {
              this.alertaOtp("Sus datos no coinciden.");
            }
          },
          (e) => {
            if (e.error) {
              this.spinner.hide();
              this.alerta(e.error);
            }
          }
        );
      },
      (e) => {
        if (e.error) {
          this.spinner.hide();
          this.alertaOtp(e.error);
        }
      }
    );
  }

  alertaOtp(msg: string) {
    this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Clave Temporal (OTP)",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
  }

  alerta(msg: string) {
    this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Información",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
  }

  regresar() {
    const dialogRef = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Código de Acceso",
        content:
          "Si regresa deberá volver a generar un código de acceso.\n" +
          "¿Desea regresar a la página principal?",
        buttons: false,
        btnAceptar: false,
        btnCancelar: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formOTP.reset();
        this.flagOtpValidation = false;
      }
    });
  }

  scrollY() {
    window.scrollTo({ top: 2000, behavior: "smooth" });
  }

  validateCed(ced) {
    const cedulaident: any = ced;
    if (cedulaident) {
      if (cedulaident.length == 10) {
        const cad = cedulaident;
        let total = 0;
        const longitud = cad.length;
        const longcheck = longitud - 1;

        if (cad !== "" && longitud === 10) {
          for (let i = 0; i < longcheck; i++) {
            if (i % 2 === 0) {
              let aux = cad.charAt(i) * 2;
              if (aux > 9) {
                aux -= 9;
              }
              total += aux;
            } else {
              total += parseInt(cad.charAt(i));
            }
          }

          total = total % 10 ? 10 - (total % 10) : 0;

          if (cad.charAt(longitud - 1) == total) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  dniCheckCedRuc() {
    /*KBastidas Validacion para invalidar el formato del Pasaporte Ini*/
    if (this.formInicio.controls.tipoIdentificacion.value === "P") {
      return false;
    }
    /*KBastidas Validacion para invalidar el formato del Pasaporte Fin*/
    this.formInicio.controls.identificacion.markAsTouched();
    if (this.formInicio.controls.identificacion.valid) {
      if (this.formInicio.controls.identificacion.value.length === 10) {
        if (
          this.noDNI.includes(
            String(this.formInicio.controls.identificacion.value)
          )
        ) {
          this.formInicio.controls.identificacion.setErrors({
            serverError: "* Ingrese un número de cédula válido ",
          });
        } else {
          if (this.validateCed(this.formInicio.controls.identificacion.value)) {
            this.formInicio.controls.identificacion.setErrors({
              serverError: null,
            });
            this.formInicio.controls.identificacion.updateValueAndValidity();
          } else {
            this.formInicio.controls.identificacion.setErrors({
              serverError: "* Ingrese un número de cédula válido ",
            });
          }
        }
      } else if (this.formInicio.controls.identificacion.value.length > 10) {
        if (
          this.noRUC.includes(
            String(this.formInicio.controls.identificacion.value)
          )
        ) {
          this.formInicio.controls.identificacion.setErrors({
            serverError: "* Ingrese un número de ruc válido ",
          });
        } else {
          if (this.validateRuc(this.formInicio.controls.identificacion.value)) {
            this.formInicio.controls.identificacion.setErrors({
              serverError: null,
            });
            this.formInicio.controls.identificacion.updateValueAndValidity();
          } else {
            this.formInicio.controls.identificacion.setErrors({
              serverError: "* Ingrese un número de ruc válido ",
            });
          }
        }
      } else {
        this.formInicio.controls.identificacion.setErrors({
          serverError: null,
        });

        this.formInicio.controls.identificacion.updateValueAndValidity();
      }
    }
  }

  get formValues() {
    return this.formInicio.controls;
  }

  validateRuc(ruc) {
    if (ruc.substr(-3) == "000" || ruc.substr(-3) > "009") {
      return false;
    } else {
      let numero = ruc;
      // alert(numero); /

      var suma = 0;
      var residuo = 0;
      var pri = false;
      var pub = false;
      var nat = false;
      var numeroProvincias = 24;
      var modulo = 11;

      // Verifico que el campo no contenga letras /
      var ok = 1;
      for (let i = 0; i < numero.length && ok == 1; i++) {
        var n = parseInt(numero.charAt(i));
        if (isNaN(n)) ok = 0;
      }
      if (ok == 0) {
        alert("No puede ingresar caracteres en el número");
        return false;
      }

      if (numero.length < 10) {
        alert("El número ingresado no es válido");
        return false;
      }

      // Los primeros dos digitos corresponden al codigo de la provincia /
      let provincia = numero.substr(0, 2);
      if (provincia < 1 || provincia > numeroProvincias) {
        //alert('El código de la provincia (dos primeros dígitos) es inválido');
        return false;
      }

      // Aqui almacenamos los digitos de la cedula en variables. /
      let d1 = numero.substr(0, 1);
      let d2 = numero.substr(1, 1);
      let d3 = numero.substr(2, 1);
      let d4 = numero.substr(3, 1);
      let d5 = numero.substr(4, 1);
      let d6 = numero.substr(5, 1);
      let d7 = numero.substr(6, 1);
      let d8 = numero.substr(7, 1);
      let d9 = numero.substr(8, 1);
      let d10 = numero.substr(9, 1);

      // El tercer digito es: /
      // 9 para sociedades privadas y extranjeros   /
      // 6 para sociedades publicas /
      // menor que 6 (0,1,2,3,4,5) para personas naturales /

      if (d3 == 7 || d3 == 8) {
        alert("El tercer dígito ingresado es inválido");
        return false;
      }

      // Solo para personas naturales (modulo 10) /
      if (d3 < 6) {
        nat = true;
        var p1 = d1 * 2;
        if (p1 >= 10) p1 -= 9;
        var p2 = d2 * 1;
        if (p2 >= 10) p2 -= 9;
        var p3 = d3 * 2;
        if (p3 >= 10) p3 -= 9;
        var p4 = d4 * 1;
        if (p4 >= 10) p4 -= 9;
        var p5 = d5 * 2;
        if (p5 >= 10) p5 -= 9;
        var p6 = d6 * 1;
        if (p6 >= 10) p6 -= 9;
        var p7 = d7 * 2;
        if (p7 >= 10) p7 -= 9;
        var p8 = d8 * 1;
        if (p8 >= 10) p8 -= 9;
        var p9 = d9 * 2;
        if (p9 >= 10) p9 -= 9;
        modulo = 10;
      } else if (d3 == 6) {
        // Solo para sociedades publicas (modulo 11) /
        // Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 /
        pub = true;
        p1 = d1 * 3;
        p2 = d2 * 2;
        p3 = d3 * 7;
        p4 = d4 * 6;
        p5 = d5 * 5;
        p6 = d6 * 4;
        p7 = d7 * 3;
        p8 = d8 * 2;
        p9 = 0;
      } else if (d3 == 9) {
        // Solo para entidades privadas (modulo 11) /
        pri = true;
        p1 = d1 * 4;
        p2 = d2 * 3;
        p3 = d3 * 2;
        p4 = d4 * 7;
        p5 = d5 * 6;
        p6 = d6 * 5;
        p7 = d7 * 4;
        p8 = d8 * 3;
        p9 = d9 * 2;
      }

      suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
      residuo = suma % modulo;

      /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
      let digitoVerificador = residuo == 0 ? 0 : modulo - residuo;

      /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
      if (pub == true) {
        if (digitoVerificador != d9) {
          //alert('El ruc de la empresa del sector público es incorrecto.');
          return false;
        }
        /* El ruc de las empresas del sector publico terminan con 0001*/
        if (numero.substr(9, 4) != "0001") {
          //alert('El ruc de la empresa del sector público debe terminar con 0001');
          return false;
        }
      } else if (pri == true) {
        if (digitoVerificador != d10) {
          //alert('El ruc de la empresa del sector privado es incorrecto.');
          return false;
        }
        if (numero.substr(10, 3) != "001") {
          //alert('El ruc de la empresa del sector privado debe terminar con 001');
          return false;
        }
      } else if (nat == true) {
        if (digitoVerificador != d10) {
          //alert('El número de cédula de la persona natural es incorrecto.');
          return false;
        }
        if (numero.length > 10 && numero.substr(10, 3) != "001") {
          //alert('El ruc de la persona natural debe terminar con 001');
          return false;
        }
      }
      return true;
      /*if (ruc[2] < 6) {
        const provinceNum = parseInt(ruc.substring(0, 2));
        if (provinceNum < 25 && provinceNum > 0) {
          let total = 0;
          const arrayCoeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
          const digitVeriRec = parseInt(ruc.charAt(9) + '');
          for (let i = 0; i < arrayCoeficientes.length; i++) {
            const valor = parseInt(arrayCoeficientes[i] + '') * parseInt(ruc.charAt(i) + '');
            total = valor >= 10 ? total + (valor - 9) : total + valor;
          }
          const digitVeriObt = total >= 10 ? (total % 10) != 0 ? 10 - (total % 10) : (total % 10) : total;
          if (digitVeriObt == digitVeriRec) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (ruc[2] == '6') {
        const provinceNum = parseInt(ruc.substring(0, 2));
        if (provinceNum < 25 && provinceNum > 0) {
          if (ruc[9] == '0') {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (ruc[2] == '9') {
        const provinceNum = parseInt(ruc.substring(0, 2));
        if (provinceNum < 25 && provinceNum > 0) {
          let total = 0;
          const arrayCoeficientes = [4, 3, 2, 7, 6, 5, 4, 3, 2];
          const digitVeriRec = parseInt(ruc.charAt(9) + '');
          for (let i = 0; i < arrayCoeficientes.length; i++) {
            const valor = parseInt(arrayCoeficientes[i] + '') * parseInt(ruc.charAt(i) + '');
            total = valor >= 10 ? total + (valor - 9) : total + valor;
          }
          const digitVeriObt = total >= 10 ? (total % 11) != 0 ? 10 - (total % 11) : (total % 11) : total;
          if (digitVeriObt == digitVeriRec) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }*/
    }
  }

  numberOnly($event: KeyboardEvent) {
    if (
      $event.keyCode <= 45 ||
      $event.keyCode > 57 ||
      $event.keyCode == 47 ||
      $event.keyCode == 46
    ) {
      return false;
    }
    return true;
  }
}